import { ArrowLeftOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import React, { useEffect, useState } from 'react';

import { fetchCategories } from 'redux/actions/CategoryAction';
import { getVendors } from 'redux/actions/VendorsAction';

import {
  createApprovalRule,
  deleteApprovalLevel,
  editApprovalRule,
  fetchApprovalRule,
  fetchApprovalRules,
  fetchApprovers,
  fetchTransactionTypes,
} from 'redux/actions/ApprovalAction';

import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Loading from 'components/UI/Loading';
import { capitalizeFirstLetter } from 'utils/helper';

import ApprovalSelect from 'components/UI/CustomSelect/ApprovalSelect';
import { categoryOptions, transactionTypeOptions } from './components/approvalData';

import { InfoCircleIcon, PlusDropDownIcon } from 'assets/icons';
import DeleteDialog from 'components/DeleteDialog';
import { toastError } from 'components/UI/toast';
import { useDebounce } from 'hooks/useDebounce';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { getBalances, getBudgets } from 'redux/actions/BudgetsAction';
import { RESET_BLOCK_APPROVAL } from 'redux/reducers/ApprovalReducer';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { addNewApproverLevel } from '../../redux/actions/ApprovalAction';
import ConditionRowComponent from './components/conditionRowComponent';
import './components/styles.scss';

const MAX_APPROVALS_LEVEL = 3;

const ApprovalForm = ({
  isEdit = false,
  IsOtherPopoverOpen = () => null,
  setIsOtherPopoverOpen,
  selectedRule,
  isEditing,
  data,
  setData,
  getApprovalLevelPayload,
  fetchingApprovers,
}) => {
  const thresholdOptions = [
    { value: 'all', label: 'All of' },
    { value: 'any', label: 'Any of' },
  ];

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { categoryCode } = useParams();

  const [categories, setCategories] = useState([]);
  const [vendorsData, setVendorsData] = useState([]);
  const [budgetsData, setBudgetsData] = useState([]);
  const [accountsData, setAccountData] = useState([]);
  const [thirdOption, setThirdOptions] = useState({
    account: null,
    budget: null,
    vendor: null,
    category: null,
    type: null,
  });
  const [transactionTypesList, setTransactionTypesList] = useState([]);
  const [approvalData, setApproval] = useState(null);
  const [editedData, setEditedData] = useState({
    name: '',
    condition: {
      oldConditions: [],
      newConditions: [{ trigger: '', operator: '', operands: [], code: '' }],
    },
    approver: {
      oldApprovers: [],
      newApprovers: [{ code: '', approvers: [] }],
    },
  });
  const [approvalLevelDelete, setDeleteApprovalLevel] = useState(false);
  const [approvalLevelPayload, setApprovalLevelPayload] = useState(false);

  const {
    fetchApprovers: { data: approversData, loading: isFetchingApprovers },
    fetchApprovalRules: {},
    fetchTransactionTypes: {
      data: transactionTypes,
      loading: isFetchingTransactionTypes,
    },
    createApprovalRule: {
      loading: isCreatingApprovalRule,
      success: isApprovalRuleCreated,
    },
    deleteApprovalLevel: { loading: isDeletingLevel, success: approvalLevelDeleted },
    editApprovalRule: { loading: isRuleEditing, success: isEdited },
    addNewApproverLevel: { loading: isAddingApprovers, success: isApproversAdded },
  } = useSelector(({ approval }) => approval);

  const {
    fetchCategories: { data: categoryData, success: isCatSuccess },
    createCategories: { loading: isCatLoading },
  } = useSelector(({ categories }) => categories);

  const {
    deleteCondition: { loading, isConditionDeleting, success: isConditionDeleted },
  } = useSelector(({ approval }) => approval);

  const {
    getBudget: { data: budgetData },
    getBalances: { data: { balances: accountData } = {} },
    getTransferableBalance: { data: budgetBalance = {}, loading: loadingTransferbalance },
  } = useSelector(({ budgets }) => budgets);

  const {
    getVendor: { loading: vendorLoading, data: vendorData },
  } = useSelector(({ vendors }) => vendors);

  const { permissions, isPremium } = allPermissions();
  const canviewVendor = hasPermission({
    permissions,
    scopes: ['vendor-*', 'vendor-view'],
  });

  const MAX_APPROVALS_LEVEL = isPremium ? 3 : 1;

  const onHandleNameChange = (event) => {
    if (isEditing) {
      setData({ ...data, name: event.target.value });
      setEditedData({ ...editedData, name: event.target.value });
    } else {
      setData({ ...data, name: event.target.value });
    }
  };

  const onAddNewConditions = () => {
    const { rules } = data;
    rules.push({
      type: 'and',
      name: '',
      'condition-one': {
        options: [],
        selectedValue: null,
      },
      'condition-two': {
        options: [{ value: 'is', label: 'is exactly' }],
        selectedValue: null,
      },
      'condition-three': {
        options: [],
        selectedValue: null,
      },
    });
    if (isEditing) {
      setEditedData({
        ...editedData,
        condition: {
          ...editedData.condition,
          newConditions: [...editedData.condition.newConditions, {}],
        },
      });
    }
    setConditionOptionsForRules(rules);
  };

  const onAddNewApprovalMembers = () => {
    setData({
      ...data,
      approvers: [
        ...data?.approvers,
        {
          rank: '',
          threshold: '',
          reviewers: [],
        },
      ],
    });
    if (isEditing) {
      setEditedData({
        ...editedData,
        approver: {
          ...editedData.approver,
          newApprovers: [...editedData.approver.newApprovers, {}],
        },
      });
    }
  };

  useEffect(() => {
    if (approvalLevelDeleted) {
      setDeleteApprovalLevel(false);
    }
  }, [approvalLevelDeleted]);

  /** @param {typeof data.rules} rules */
  const setConditionOptionsForRules = (rules, update = true) => {
    let availableOptions = categoryOptions.slice();
    let amountOperators = transactionTypeOptions['amount'].secondOption.slice();
    let genericOperators = transactionTypeOptions['type'].secondOption.slice();
    for (let index = 0; index < rules.length; ++index) {
      rules[index]['condition-one'].options = availableOptions.slice();

      const { name } = rules[index];
      if (name === 'amount') {
        rules[index]['condition-two'].options = amountOperators.slice();

        if (!rules[index]['condition-two'].selectedValue) {
          rules[index]['condition-two'].selectedValue = amountOperators[0];
        }

        const selectedOperator = rules[index]['condition-two']?.selectedValue;
        amountOperators = amountOperators.filter((operator) => {
          switch (selectedOperator?.value) {
            case 'eq':
              return (
                selectedOperator?.value !== operator.value && operator.value === 'eq'
              );
            case 'gt':
              return (
                selectedOperator?.value !== operator.value &&
                ['lt', 'lte'].includes(operator.value)
              );
            case 'lt':
              return (
                selectedOperator?.value !== operator.value &&
                ['gt', 'gte'].includes(operator.value)
              );
            case 'gte':
              return (
                selectedOperator?.value !== operator.value &&
                ['lt', 'lte'].includes(operator.value)
              );
            case 'lte':
              return (
                selectedOperator?.value !== operator.value &&
                ['gt', 'gte'].includes(operator.value)
              );
            default:
              return selectedOperator?.value !== operator.value;
          }
        });
      }

      let operatorsWithAny = transactionTypeOptions[name]?.secondOption?.slice();
      if (['budget', 'vendor', 'category', 'account'].includes(name)) {
        rules[index]['condition-two'].options = operatorsWithAny.slice();

        const selectedOperator = rules[index]['condition-two'].selectedValue;
        operatorsWithAny = operatorsWithAny.filter((operator) => {
          return selectedOperator?.value !== operator.value;
        });
      }

      if (!['amount', 'budget', 'vendor', 'category', 'account'].includes(name)) {
        rules[index]['condition-two'].options = genericOperators.slice();

        const selectedOperator = rules[index]['condition-two'].selectedValue;
        genericOperators = genericOperators.filter((operator) => {
          return selectedOperator?.value !== operator.value;
        });
      }

      const conditionToDisplayAmountOption = () => {
        let count = 0;
        let displayAmountOption = true;
        for (let item of rules) {
          if (item.name === 'amount') {
            count += 1;

            if (item['condition-two']?.selectedValue?.value === 'eq')
              displayAmountOption = false;
          }
        }

        return count < 2 && displayAmountOption;
      };

      availableOptions = availableOptions.filter((option) => {
        if (!['amount'].includes(option.value)) return name !== option.value;
        return conditionToDisplayAmountOption();
      });
    }

    if (update) setData({ ...data, rules });
    return rules;
  };

  const setConditionName = (typeValue, index) => {
    const type = typeValue.value;
    let rules = data?.rules;
    let selectedItem = rules[index];
    selectedItem['condition-two'].selectedValue =
      type === 'amount' ? null : transactionTypeOptions[type]?.secondOption?.slice()?.[0];
    selectedItem.name = type;
    selectedItem['condition-one'].selectedValue = typeValue;
    if (isEditing) {
      setConditionOptionsForRules(rules);
      const newConditions = getIsEditingConditionName(index, type, typeValue);
      setEditedData({
        ...editedData,
        condition: {
          ...editedData.condition,
          newConditions,
        },
      });
    } else {
      setConditionOptionsForRules(rules);
    }
    computeThirdConditionsByIndex(type, index);
  };

  const getOptionValue = (value, index, conditionLevel) => {
    let rules = data?.rules;
    let selectedItem = rules[index];
    selectedItem[`condition-${conditionLevel}`].selectedValue = value;

    if (conditionLevel === 'two' && selectedItem.name === 'amount') {
      rules.find((rule, ruleIndex) => {
        return ruleIndex > index && rule.name === 'amount';
      });

      rules = setConditionOptionsForRules(rules, false);
    }

    // clear third condition when condition two changes
    if (
      conditionLevel === 'two' &&
      ['budget', 'vendor', 'category', 'account', 'type'].includes(selectedItem.name)
    ) {
      const conditionTwoType = selectedItem['condition-two'].selectedValue?.value;

      if (selectedItem.name === 'type') {
        if (conditionTwoType === 'not:is') {
          selectedItem[`condition-three`].selectedValue =
            selectedItem[`condition-three`].selectedValue?.value !== 'all'
              ? selectedItem[`condition-three`].selectedValue
              : null;
          selectedItem['condition-three'].options = transactionTypesList.filter(
            (item) => item.feature_name !== 'all',
          );
        } else {
          selectedItem['condition-three'].options = transactionTypesList;
        }
      }
    }

    if (isEditing) {
      let newOptionValue = [];
      let conditionLength = editedData?.condition?.newConditions?.length;
      let approvalDataConditionLength = approvalData?.conditions?.length;
      let selectedConditionCode = '';
      let conditionCodeNextAmount = '';
      if (conditionLevel === 'two' && selectedItem.name === 'amount') {
        newOptionValue = getIsEditingOptionValue(index, '', 'three');
        selectedConditionCode = getCodeByIndex(index);
      }

      if (conditionLength > approvalDataConditionLength) {
        newOptionValue = getIsEditingOptionValue(index, value, conditionLevel);
      } else {
        newOptionValue = getIsEditingOptionValue(index, value, conditionLevel);
        selectedConditionCode = getCodeByIndex(index);
      }
      setEditedData({
        ...editedData,
        condition: {
          ...editedData.condition,
          oldConditions: [...editedData.condition.oldConditions, selectedConditionCode],
          newConditions: newOptionValue,
        },
      });
    }
    setData({ ...data, rules });
  };

  const onChangeApproverThreshold = (thresholdValue, index, rank) => {
    let approvers = data?.approvers;
    let selectedItem = approvers[index];
    selectedItem.threshold = thresholdValue.value;
    selectedItem.rank = index + 1;
    setData({ ...data, approvers });
    if (isEditing) {
      handleIsEditingApproversThreshold(thresholdValue, index, rank);
    }
  };

  const onHandleGetSelectedApprovers = (list, index, rank) => {
    let approvers = data?.approvers;
    let selectedItem = approvers[index];
    selectedItem.reviewers = list;
    if (isEditing) {
      setData({ ...data, approvers });
      handleIsEditingApprovers(list, index, rank);
    } else {
      setData({ ...data, approvers });
    }
  };

  const getExisitingUserCode = (rank) => {
    if (data.approvers.length <= approvalData.approved_by.length) {
      return approvalData.approved_by
        .find((item) => item.rank === rank)
        .approvers.map((user) => {
          return user.user.code;
        });
    }
  };

  const handleIsEditingApprovers = (list, index, rank) => {
    let oldApproverDataCode = null;
    let newUser = { approvers: [] };
    let existingUser = getExisitingUserCode(rank);
    let { oldApprovers, newApprovers } = editedData.approver;
    const { approved_by } = approvalData;
    let approverLevelByRank = approved_by.find((item) => item.rank === rank);
    let listOfOldApprovers = approverLevelByRank;
    if (data.approvers.length <= approved_by.length) {
      if (list.length < listOfOldApprovers.approvers.length) {
        oldApproverDataCode = approverLevelByRank.approvers.find((item) => {
          return !list.includes(item.user.code);
        }).code;
      } else if (list.length >= approverLevelByRank.approvers.length) {
        for (let i = 0; i < list.length; i++) {
          if (list[i] !== existingUser[i]) {
            newUser.approvers.push(list[i]);
            newUser.code = approverLevelByRank.code;
          }
        }
      }
    }
    if (newUser.approvers.length) {
      newApprovers[index] = newUser;
    }
    if (oldApproverDataCode) {
      oldApprovers = [...new Set([...oldApprovers, oldApproverDataCode])];
    }

    setEditedData({
      ...editedData,
      approver: {
        ...editedData.approver,
        oldApprovers,
        newApprovers,
      },
    });
  };

  const handleIsEditingApproversThreshold = (thresholdValue, index, rank) => {
    const { newApprovers, oldApprovers } = editedData.approver;
    if (data.approvers.length <= approvalData.approved_by.length) {
      const updatedList = newApprovers.map((approver, approverIndex) => {
        if (index === approverIndex) {
          return {
            ...approver,
            threshold: thresholdValue.value,
            code: approvalData.approved_by.find((item) => item.rank === rank).code,
          };
        }
        return approver;
      });
      setEditedData({
        ...editedData,
        approver: {
          ...editedData.approver,
          newApprovers: updatedList,
        },
      });
    }
  };

  const onHandleAmountChange = (event, index) => {
    const { value, rawValue } = event.target;
    const rules = data?.rules;
    let selectedItem = rules[index];
    selectedItem['condition-three'].amount = rawValue ?? value;
    setData({ ...data, rules });
    if (isEditing) {
      let code = '';
      let newConditions = [];
      const amountValue = { value, rawValue };
      if (editedData.condition.newConditions.length > approvalData.conditions.length) {
        newConditions = getIsEditingOptionValue(index, amountValue, 'three');
      } else {
        code = getCodeByIndex(index);
        newConditions = getIsEditingOptionValue(index, amountValue, 'three');
      }
      setEditedData({
        ...editedData,
        condition: {
          ...editedData.condition,
          oldConditions: [...editedData.condition.oldConditions, code],
          newConditions,
        },
      });
    }
  };

  const getIsEditingOptionValue = (index, value, conditionLevel) => {
    let newConditions = [];
    let editedDataConditions = editedData.condition.newConditions;
    const selectedData = data.rules[index];
    const selectedDataName = selectedData.name;
    let amountOperator = '';

    if (selectedDataName === 'amount') {
      amountOperator = selectedData['condition-two'].selectedValue?.value;
    }

    newConditions = editedDataConditions.map((condition, conditionIndex) => {
      if (conditionIndex === index) {
        return {
          trigger: selectedDataName,
          operator:
            selectedDataName === 'amount'
              ? amountOperator
              : selectedData['condition-two'].selectedValue?.value,
          operands:
            selectedDataName === 'amount'
              ? typeof value.value === 'number'
                ? Number(value.rawValue) * 100 ?? Number(value.value) * 100
                : selectedData['condition-three'].amount * 100
              : selectedDataName === 'type'
              ? [selectedData['condition-three'].selectedValue?.feature_name]
              : selectedData['condition-two'].selectedValue?.value === 'any'
              ? selectedData['condition-three'].selectedValue?.map((item) => item.code) //for any
              : [selectedData['condition-three'].selectedValue?.code],
        };
      }
      return condition;
    });

    return newConditions;
  };

  const getCodeByIndex = (index) => {
    const selectedCode = approvalData.conditions.find(
      (item, itemIndex) => itemIndex === index,
    )?.code;
    return selectedCode;
  };
  const getIsEditingConditionName = (index, type, typeValue) => {
    const editedDataConditions = editedData.condition.newConditions;
    let selectedData = data.rules[index];
    const newConditions = editedDataConditions.map((condition, conditionIndex) => {
      if (conditionIndex === index) {
        return {
          trigger: selectedData.name,
          operator: selectedData['condition-two']?.selectedValue?.value,
          operands: [],
        };
      }
      return condition;
    });
    return newConditions;
  };

  const computeThirdConditionsByIndex = (type, index) => {
    let rules = data?.rules;
    let selectedItem = rules.find((item, itemIndex) => itemIndex === index);
    if (type === 'category') {
      selectedItem['condition-three'] = {
        selectedValue: null,
        options: categories,
      };
      selectedItem['condition-two'] = {
        ...selectedItem['condition-two'],
        options: transactionTypeOptions['category'].secondOption,
      };
    } else if (type === 'vendor') {
      selectedItem['condition-three'] = {
        selectedValue: null,
        options: vendorsData,
      };
      selectedItem['condition-two'] = {
        options: transactionTypeOptions['vendor'].secondOption,
        ...selectedItem['condition-two'],
      };
    } else if (type === 'budget') {
      selectedItem['condition-three'] = {
        selectedValue: null,
        options: budgetsData,
      };
      selectedItem['condition-two'] = {
        options: transactionTypeOptions['budget'].secondOption,
        ...selectedItem['condition-two'],
      };
    } else if (type === 'account') {
      selectedItem['condition-three'] = {
        selectedValue: null,
        options: accountsData,
      };
      selectedItem['condition-two'] = {
        options: transactionTypeOptions['account'].secondOption,
        ...selectedItem['condition-two'],
      };
    } else if (type === 'type') {
      selectedItem['condition-three'] = {
        options: transactionTypesList,
        selectedValue: null,
      };
      selectedItem['condition-two'] = {
        options: transactionTypeOptions['type'].secondOption,
        ...selectedItem['condition-two'],
      };
    } else if (type === 'amount') {
      selectedItem['condition-three'] = {
        amount: '',
      };
    }

    rules[index] = selectedItem;
    setData({
      ...data,
      rules,
    });
  };

  const [value, setValue] = useState({ budget: '' });
  const debouncedBudget = useDebounce(value.budget, 200);

  useEffect(() => {
    if (!accountData?.length) dispatch(getBalances());
    if (!budgetData?.budgets?.length) dispatch(getBudgets({ status: 'active' }));
  }, []);

  const getInputChange = (val, type) => {
    setValue({ ...value, [type]: val });
  };

  useEffect(() => {
    const budgetListed = budgetsData?.find((option) =>
      option.label.toLowerCase().includes(debouncedBudget.toLowerCase()),
    );
    if (!budgetListed && debouncedBudget) {
      dispatch(getBudgets({ status: 'active', search: debouncedBudget?.toLowerCase() }));
    }
  }, [debouncedBudget]);

  useEffect(() => {
    if (!categoryData) dispatch(fetchCategories());
    else if (categoryData) {
      const newData = categoryData?.categories?.map((category) => {
        return {
          value: category.slug,
          label: category.name,
          code: category.code,
        };
      });
      setCategories(newData);
      setThirdOptions({ ...thirdOption, category: newData });
    }
  }, [isCatSuccess]);

  useEffect(() => {
    if (!vendorData && canviewVendor) dispatch(getVendors());
    else if (vendorData) {
      const newData = vendorData?.vendors?.map((vendor) => {
        return {
          value: vendor?.name,
          label: vendor?.name,
          code: vendor?.code,
        };
      });
      setVendorsData(newData);
      setThirdOptions({ ...thirdOption, vendor: newData });
    }
  }, [vendorData]);

  useEffect(() => {
    if (budgetData) {
      const newData = budgetData?.budgets
        ?.filter(
          (item) =>
            !['closed', 'inactive', 'deleted'].includes(item.status.toLowerCase()),
        )
        ?.map((budget) => {
          return {
            value: budget?.name,
            label: budget?.name,
            code: budget?.code,
          };
        });
      setBudgetsData(newData);
      setThirdOptions({ ...thirdOption, budget: newData });
    }
  }, [budgetData?.budgets]);

  useEffect(() => {
    if (accountData) {
      const newData = accountData?.map((account) => {
        return {
          value: account?.name,
          label: account?.name,
          code: account?.code,
        };
      });
      setAccountData(newData);
      setThirdOptions({ ...thirdOption, account: newData });
    }
  }, [accountData]);

  useEffect(() => {
    if (!transactionTypes) {
      dispatch(fetchTransactionTypes());
    } else if (transactionTypes) {
      const newData = transactionTypes.map((type) => {
        return {
          ...type,
          label: type.name,
          value: type.feature_name,
        };
      });
      setTransactionTypesList(newData);
      setThirdOptions({
        ...thirdOption,
        type: newData,
      });
    }
  }, [transactionTypes]);

  useEffect(() => {
    if (!approversData) dispatch(fetchApprovers());
  }, [approversData]);

  useEffect(() => {
    if (selectedRule && categories && vendorsData) {
      populateApprovalConditions(selectedRule);
      setApproval(selectedRule);
      generateEditedData(selectedRule);
    }
  }, [selectedRule]);

  const populateApprovalConditions = (selectedRule) => {
    const existingApprovers = generateTheApprovers(selectedRule);
    const rules = generateNewApprovalRules(selectedRule.conditions);

    setData({
      ...data,
      name: selectedRule.name,
      rules: setConditionOptionsForRules(rules, false),
      approvers: existingApprovers,
    });
  };

  //this is function is for when an approval is being edited
  const generateTheApprovers = (approvers) => {
    const newApprovers = approvers.approved_by
      .map((approvalFlow) => {
        return {
          rank: approvalFlow.rank,
          threshold: approvalFlow.approvers_threshold,
          reviewers: approvalFlow.approvers.map(({ user: { code } }) => code),
        };
      })
      .sort((level_one, level_two) => level_one.rank - level_two.rank);
    return newApprovers;
  };

  useEffect(() => {
    if (location?.state?.operator) {
      setData({ ...data, rules: generateNewApprovalRules([location?.state]) });
    }
  }, [location?.state?.operator]);

  const generateNewApprovalRules = (rules) => {
    return rules.map((condition, index) => {
      let type = condition.trigger;
      const rulesOperands =
        type === 'amount' ? condition : condition.operands[0][condition.trigger];
      if (type === 'amount') {
        return {
          type: index === 0 ? 'When transaction' : 'And',
          name: type,
          'condition-one': {
            options: categoryOptions,
            selectedValue: { label: 'Amount', value: 'amount' },
          },
          'condition-two': {
            options: transactionTypeOptions['amount'].secondOption,
            selectedValue: transactionTypeOptions['amount'].secondOption.find(
              (item) => item.value === rulesOperands.operator,
            ),
          },
          'condition-three': {
            amount: rulesOperands.operands[0].operand / 100,
          },
        };
      }

      if (type === 'category') {
        return {
          type: index === 0 ? 'when transaction' : 'and',
          name: type,
          'condition-one': {
            options: categoryOptions,
            selectedValue: { label: capitalizeFirstLetter(type), value: type },
          },
          'condition-two': {
            options: transactionTypeOptions['category'].secondOption,
            selectedValue: transactionTypeOptions['category'].secondOption.find(
              (item) => item.value === condition.operator,
            ),
          },
          'condition-three': {
            options: categories,
            selectedValue:
              rulesOperands.operator === 'is'
                ? {
                    code: rulesOperands.code,
                    label: rulesOperands.name,
                    value: rulesOperands.name,
                    isFixed: rulesOperands?.isFixed,
                  }
                : condition.operands.map(({ category }) => ({
                    code: category.code,
                    label: category.name,
                    value: category.name,
                    isFixed: category?.isFixed,
                  })),
          },
        };
      }

      if (type === 'type') {
        return {
          type: index === 0 ? 'when transaction' : 'and',
          name: type,
          'condition-one': {
            options: categoryOptions,
            selectedValue: { label: capitalizeFirstLetter(type), value: type },
          },
          'condition-two': {
            options: [{ value: 'is', label: 'is exactly' }],
            selectedValue: { value: 'is', label: 'is exactly' },
          },
          'condition-three': {
            options: transactionTypesList,
            selectedValue: {
              code: rulesOperands.code,
              label: rulesOperands.name,
              value: rulesOperands.name,
              feature_name: rulesOperands.feature_name,
            },
          },
        };
      }

      if (type === 'vendor') {
        return {
          type: index === 0 ? 'when transaction' : 'and',
          name: type,
          'condition-one': {
            options: categoryOptions,
            selectedValue: { label: capitalizeFirstLetter(type), value: type },
          },
          'condition-two': {
            options: transactionTypeOptions['vendor'].secondOption,
            selectedValue: transactionTypeOptions['vendor'].secondOption.find(
              (item) => item.value === condition.operator,
            ),
          },
          'condition-three': {
            options: vendorsData,
            selectedValue:
              rulesOperands.operator === 'is'
                ? {
                    code: rulesOperands.code,
                    label: rulesOperands.name,
                    value: rulesOperands.slug,
                  }
                : condition.operands.map(({ vendor }) => ({
                    code: vendor.code,
                    label: vendor.name,
                    value: vendor.code,
                  })),
          },
        };
      }

      if (type === 'budget') {
        return {
          type: index === 0 ? 'when transaction' : 'and',
          name: type,
          'condition-one': {
            options: categoryOptions,
            selectedValue: { label: capitalizeFirstLetter(type), value: type },
          },
          'condition-two': {
            options: transactionTypeOptions['budget'].secondOption,
            selectedValue: transactionTypeOptions['budget'].secondOption.find(
              (item) => item.value === condition.operator,
            ),
          },
          'condition-three': {
            options: budgetsData,
            selectedValue:
              rulesOperands.operator === 'is'
                ? {
                    code: rulesOperands.code,
                    label: rulesOperands.name,
                    value: rulesOperands.slug,
                  }
                : condition.operands.map(({ budget }) => ({
                    code: budget.code,
                    label: budget.name,
                    value: budget.code,
                  })),
          },
        };
      }

      if (type === 'account') {
        return {
          type: index === 0 ? 'when transaction' : 'and',
          name: type,
          'condition-one': {
            options: categoryOptions,
            selectedValue: { label: capitalizeFirstLetter(type), value: type },
          },
          'condition-two': {
            options: transactionTypeOptions['account'].secondOption,
            selectedValue: transactionTypeOptions['account'].secondOption.find(
              (item) => item.value === condition.operator,
            ),
          },
          'condition-three': {
            options: accountsData,
            selectedValue:
              rulesOperands.operator === 'is'
                ? {
                    code: rulesOperands.code,
                    label: rulesOperands.name,
                    value: rulesOperands.slug,
                  }
                : condition.operands.map(({ account }) => ({
                    code: account.code,
                    label: account.name,
                    value: account.code,
                  })),
          },
        };
      }
    });
  };

  const getApprovalThreshold = (threshold) => {
    if (['all', -1].includes(threshold)) return { value: 'all', label: 'All of' };
    else if (['any', 1].includes(threshold)) return { value: 'any', label: 'Any of' };
    return '';
  };

  const generateEditedData = (data) => {
    const emptyObject = {},
      emptyApproversObject = {};
    const conditions = Array.from({ length: data.conditions.length }, () => emptyObject);
    const approvers = Array.from(
      { length: data.approved_by.length },
      () => emptyApproversObject,
    );

    setEditedData({
      ...editedData,
      name: data?.name,
      condition: {
        oldConditions: [],
        newConditions: conditions,
      },
      approver: {
        oldApprovers: [],
        newApprovers: approvers,
      },
    });
  };

  const createConditionsForPayload = (data) => {
    const conditions = data.map((condition) => {
      if (condition.name === 'amount') {
        let operatorValue =
          condition['condition-two'].selectedValue?.value?.toLowerCase();
        const amount = condition['condition-three']?.amount;
        return {
          trigger: condition.name,
          operator: operatorValue,
          operands: amount === '' ? '' : Number(amount) * 100,
        };
      }

      const selectedValueProperty = condition.name === 'type' ? 'feature_name' : 'code';

      const checkThirdCondtion = !!condition['condition-three']?.selectedValue?.length
        ? condition['condition-three']?.selectedValue?.map(
            (item) => item[selectedValueProperty],
          )
        : [condition['condition-three'].selectedValue?.[selectedValueProperty]];

      return {
        trigger: condition.name,
        operator: condition['condition-two'].selectedValue?.value?.toLowerCase(),
        operands: checkThirdCondtion.filter((operand) => operand !== undefined),
      };
    });

    return conditions;
  };

  const amountConditionValidation = (conditions) => {
    let amountConditions = conditions.filter((item) => item.trigger === 'amount');

    if (amountConditions.length) {
      let amountConditionsOperator = conditions.map((item) => item.operator);

      if (amountConditions.length == 2) {
        if (amountConditionsOperator[0] === amountConditionsOperator[1]) {
          toastError('Amount conditions are conflicting, please review');
          return false;
        }
        if (
          amountConditionsOperator.includes('gte') &&
          amountConditionsOperator.includes('lte') &&
          amountConditions[0].operands === amountConditions[1].operands
        ) {
          toastError(
            "The values for 'greater than or equal to' and 'less than or equal to' conditions are conflicting",
          );
          return false;
        }
        if (amountConditionsOperator[0] === 'eq') {
          toastError(
            "The 'equal to' condition, cannot be paired with any other condition",
          );
          return false;
        }

        if (
          amountConditionsOperator.includes('gt') &&
          amountConditionsOperator.includes('gte')
        ) {
          toastError(
            "The 'greater than' condition, cannot be paired with 'greater than or equal to'",
          );
          return false;
        }
        if (
          amountConditionsOperator.includes('lt') &&
          amountConditionsOperator.includes('lte')
        ) {
          toastError(
            "The 'less than' condition, cannot be paired with 'less than or equal to'",
          );
          return false;
        }
      }
    }
    return true;
  };

  const onSubmitForm = () => {
    let requestPayload = {};
    const conditions = createConditionsForPayload(data.rules);

    requestPayload = {
      name: data?.name,
      conditions,
      reviews: data?.approvers,
    };

    if (checkForEmptyValue(requestPayload)) return toastError('All fields are required');

    if (!amountConditionValidation(requestPayload.conditions)) return;

    dispatch(createApprovalRule(requestPayload));
  };

  const removeEmptyObject = (arr) => {
    return arr.filter((element) => {
      if (Object.keys(element).length !== 0) {
        return true;
      }
      return false;
    });
  };

  const onSubmiteEditedForm = () => {
    let payload = {
      name: data?.name,
      conditions: createConditionsForPayload(data.rules),
      reviews: data?.approvers,
    };

    if (checkForEmptyValue(payload)) {
      return toastError('All fields are required');
    } else {
      if (!amountConditionValidation(payload.conditions)) return;

      let requestPayload = { code: approvalData.code };
      const {
        condition: { oldConditions, newConditions },
        approver: { oldApprovers, newApprovers },
        name,
      } = editedData;
      if (name !== approvalData.name) requestPayload.name = name;
      const updatedOldConditions = [...new Set([...oldConditions])].filter(
        (item) => item,
      );
      const updatedNewConditions = removeEmptyObject(newConditions);

      const updatedNewApprovers = removeEmptyObject(newApprovers);
      requestPayload = {
        ...requestPayload,
        condition: {
          oldConditions: updatedOldConditions,
          newConditions: updatedNewConditions,
        },
        approver: {
          oldApprovers,
          newApprovers: updatedNewApprovers,
        },
      };

      if (data.approvers.length > approvalData.approved_by.length) {
        const newApprover = data.approvers.slice(
          approvalData.approved_by.length,
          data.approvers.length,
        );
        if (checkForEmptyValue(newApprover))
          return toastError('Please select approvers for this level');
        const newApproverLevel = {
          code: approvalData.code,
          approvers: newApprover,
        };
        dispatch(addNewApproverLevel(newApproverLevel));
      }
      if (
        !updatedOldConditions.length &&
        !updatedNewConditions.length &&
        !oldApprovers.length &&
        !updatedNewApprovers.length &&
        data.approvers.length <= approvalData.approved_by.length &&
        name === approvalData.name
      ) {
        return toastError("You didn't make any edit");
      }
      if (requestPayload.name) {
        if (checkForEmptyValue(requestPayload))
          return toastError('All fields are required');
        dispatch(editApprovalRule(requestPayload));
      }
      if (
        requestPayload.condition.newConditions ||
        requestPayload.condition.oldConditions
      ) {
        dispatch(editApprovalRule(requestPayload));
      }
    }
  };

  //this is used in place of proper form validation
  //would need refactoring to only use a form library with the need for this logic
  const checkForEmptyValue = (payload) => {
    let error = [];
    for (const key in payload) {
      if (Array.isArray(payload[key])) {
        payload[key].forEach((item) => {
          for (const itemKey in item) {
            if (Array.isArray(item[itemKey])) {
              if (item[itemKey].length == 0) {
                error.push(true);
              } else {
                error.push(false);
              }
            } else if (!item[itemKey] || item[itemKey] === '') {
              error.push(true);
            } else if (item[itemKey] !== '') {
              error.push(false);
            }
          }
        });
      } else if (payload[key] !== '') {
        continue;
      }
    }
    return error.includes(true);
  };

  const onRemoveConditionItem = (index, type) => {
    let newData = data?.[type]?.filter((datum, datumIndex) => datumIndex !== index);
    if (isEditing) {
      const selectedItem = approvalData.conditions[index];
      if (selectedItem?.code) {
        setEditedData({
          ...editedData,
          condition: {
            ...editedData.condition,
            oldConditions: [...editedData.condition.oldConditions, selectedItem.code],
          },
        });
      }
    }
    setConditionOptionsForRules(newData);
  };

  const onRemoveApprovalLevel = (rank, index) => {
    if (data.approvers.length === 1) {
      return toastError("You can't delete all approval level");
    }

    if (!isEditing) {
      const newData = data?.approvers?.filter(
        (datum, datumIndex) => datumIndex !== index,
      );
      setData({
        ...data,
        approvers: newData,
      });
    } else {
      const rankExist = approvalData.approved_by.find(
        (approval) => approval.rank === rank,
      );
      if (isEditing && rankExist) {
        const selectedItemCode = approvalData.approved_by.find(
          (approval) => approval.rank === rank,
        )?.code;
        let payload = {
          approver: selectedItemCode,
          code: approvalData.code,
        };
        onHandleApprovalLevelPayload(payload);
      } else if (isEditing && !rankExist) {
        const newData = data?.approvers?.filter(
          (datum, datumIndex) => datumIndex !== index,
        );

        setData({
          ...data,
          approvers: newData,
        });
      }
    }
  };

  useEffect(() => {
    if (isApprovalRuleCreated || isEdited || isApproversAdded) {
      if (location.pathname.split('/')?.includes('categories')) {
        dispatch(fetchApprovalRules({ category: categoryCode }));
        history.goBack(-1);
      } else {
        dispatch(fetchApprovalRules());
        history.push('/compliances/rules');
      }
    }
  }, [isApprovalRuleCreated, isEdited, isApproversAdded]);

  let users =
    approversData
      ?.map((item) => {
        return item.users;
      })
      .flat(1) ?? [];

  const allReviewers = data.approvers
    .map((approver) => {
      return approver?.reviewers ?? [];
    })
    .flat(1);
  const unselectedUsers = users.filter((user) => {
    return !allReviewers.includes(user.code);
  });

  const approverAvailableUsers = data.approvers.map((approver) => {
    const approverReviewers = approver?.reviewers ?? [];
    return approverReviewers
      .map((userCode) => users.find((user) => user.code === userCode))
      .concat(unselectedUsers);
  });

  const onHandleApprovalLevelPayload = (payload) => {
    setApprovalLevelPayload(payload);
    setDeleteApprovalLevel(true);
  };

  const onHandleDeleteApprovalLevel = () => {
    dispatch(deleteApprovalLevel(approvalLevelPayload));
  };

  const onHandleCancel = () => {
    history.goBack(-1);
  };

  return (
    <>
      <div
        className="information-wrapper form-wrapper__2 ms-4 overview-holder"
        style={{ height: `calc(100vh - 100px)` }}
      >
        <form onSubmit={onSubmitForm}>
          <div className="approval-rules-container">
            <h2 className="mb-0">
              {isEditing ? `Edit approval rule` : 'Create approval rules'}
            </h2>
            {data?.approvers.length === 3 && (
              <div className="d-flex">
                <CustomButton
                  onClick={onHandleCancel}
                  disabled={
                    isCreatingApprovalRule ||
                    isRuleEditing ||
                    isConditionDeleting ||
                    isAddingApprovers
                  }
                  className="add-button add-action me-3"
                  customClass={true}
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  className="add-button dark-button"
                  customClass={true}
                  loading={
                    isCreatingApprovalRule ||
                    isRuleEditing ||
                    isConditionDeleting ||
                    isAddingApprovers
                  }
                  disabled={
                    isCreatingApprovalRule ||
                    isRuleEditing ||
                    isConditionDeleting ||
                    isAddingApprovers
                  }
                  onClick={isEditing ? onSubmiteEditedForm : onSubmitForm}
                >
                  {isEditing ? 'Edit rule' : 'Create'}
                </CustomButton>
              </div>
            )}
          </div>
          <div className="divider"></div>
          <Row className="mb-3">
            <CustomInput
              label="Rule name"
              placeholder="Enter name"
              text="text"
              name="approvalName"
              id="name"
              onChange={onHandleNameChange}
              value={data?.name}
            />
          </Row>
          <Row className="mb-3 rule-styles">
            <h6>Rule conditions</h6>
            <span>Set the triggers and rules of the approval flow</span>
          </Row>
          <Row className="mb-3">
            <ConditionRowComponent
              rules={data?.rules}
              getInputChange={getInputChange}
              setConditionName={setConditionName}
              removeCondition={onRemoveConditionItem}
              getOperatorValue={getOptionValue}
              getAmount={onHandleAmountChange}
              getOperand={getOptionValue}
            />

            <div
              className="add-button condition-cta mt-2"
              role="button"
              onClick={
                data?.rules.length === 6
                  ? () => toastError("You can't add more than 6 conditions")
                  : onAddNewConditions
              }
            >
              <PlusDropDownIcon className="me-1" /> <span>Add condition</span>
            </div>
          </Row>
          <div className="divider"></div>
          <Row className="mb-3">
            <div className="approval-wrapper mt-4">
              <span className="conditions-label">Has to be approved by</span>
              {data?.approvers?.map((approver, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="conditions-wrapper condition-information-wrapper">
                      <div className="flex-grow-1">
                        {index !== 0 && (
                          <div className="conditions-label d-flex justify-content-between align-items-center">
                            <span className="conditions-label">
                              Has to be approved by
                            </span>
                            <span
                              className="conditions-labelRemove-text"
                              onClick={() => onRemoveApprovalLevel(approver.rank, index)}
                            >
                              Remove
                            </span>
                          </div>
                        )}

                        <div className="d-flex gap-2">
                          <div className="flex-1">
                            <CustomSelect
                              name="category"
                              placeholder="Select"
                              options={thresholdOptions}
                              value={getApprovalThreshold(approver?.threshold)}
                              onChange={(thresholdValue) =>
                                onChangeApproverThreshold(
                                  thresholdValue,
                                  index,
                                  approver.rank,
                                )
                              }
                            />
                          </div>
                          <div className="flex-2">
                            <ApprovalSelect
                              approvers={approver?.reviewers ?? []}
                              users={approverAvailableUsers[index]}
                              setApprovers={(data) =>
                                onHandleGetSelectedApprovers(data, index, approver.rank)
                              }
                              loading={fetchingApprovers}
                            />
                            {''}{' '}
                          </div>
                        </div>
                      </div>
                      <Tooltip
                        placement="right"
                        title={
                          <div className="text-xs py-2 px-2">
                            <span className="fw-bold">Choose the approvers</span>
                            <div className="fw-normal mt-2">
                              Either of — Approval from any team member on the list will
                              authorize the transaction.
                              <br />
                              <br />
                              All of — Approval from all team members on the list is
                              mandatory to authorize the transaction.
                            </div>
                          </div>
                        }
                        color={'#000'}
                      >
                        <div
                          className={`information-icon ${index === 0 ? '' : 'invisible'}`}
                        >
                          <InfoCircleIcon />
                        </div>
                      </Tooltip>
                    </div>
                    {data?.approvers.length > 1 ? (
                      <div className="connector-wrapper">
                        <div className="connector-wrapper-item">
                          <span className="connector">Then</span>
                        </div>
                      </div>
                    ) : null}
                  </React.Fragment>
                );
              })}
            </div>
            <div
              className="add-button condition-cta mt-2"
              role="button"
              onClick={
                data?.approvers?.length === MAX_APPROVALS_LEVEL
                  ? () =>
                      toastError(
                        `You can only have ${MAX_APPROVALS_LEVEL} levels of approval`,
                      )
                  : onAddNewApprovalMembers
              }
            >
              <PlusDropDownIcon className="me-1" /> <span>Add approver</span>
            </div>
          </Row>
        </form>

        <div className="divider"></div>

        <div className="d-flex justify-content-end mt-3">
          <CustomButton
            onClick={onHandleCancel}
            disabled={
              isCreatingApprovalRule ||
              isRuleEditing ||
              isConditionDeleting ||
              isAddingApprovers
            }
            className="add-button add-action me-3"
            customClass={true}
          >
            Cancel
          </CustomButton>
          <CustomButton
            className="add-button dark-button"
            customClass={true}
            loading={
              isCreatingApprovalRule ||
              isRuleEditing ||
              isConditionDeleting ||
              isAddingApprovers
            }
            disabled={
              isCreatingApprovalRule ||
              isRuleEditing ||
              isConditionDeleting ||
              isAddingApprovers
            }
            onClick={isEditing ? onSubmiteEditedForm : onSubmitForm}
          >
            {isEditing ? 'Edit rule' : 'Create'}
          </CustomButton>
        </div>
      </div>
      {approvalLevelDelete && (
        <DeleteDialog
          title={`Delete this approval level`}
          subTitle="Are you sure you want to delete this approval level? This action cannot be undone."
          onCancel={() => {
            setDeleteApprovalLevel(false);
          }}
          onDelete={onHandleDeleteApprovalLevel}
          styles={{ width: 400 }}
          isLoading={isDeletingLevel}
        />
      )}
    </>
  );
};

const FetchingLoader = () => {
  return (
    <div>
      <Loading />
    </div>
  );
};

export default function ApprovalRuleCreation({ ...props }) {
  const defaultFormData = {
    name: '',
    rules: [
      {
        type: 'when transaction',
        name: '',
        'condition-one': {
          options: categoryOptions,
          selectedValue: null,
        },
        'condition-two': {
          options: [{ value: 'is', label: 'is exactly' }],
          selectedValue: null,
        },
        'condition-three': {
          options: [],
          selectedValue: null,
        },
      },
    ],
    approvers: [
      {
        rank: '',
        threshold: '',
        reviewers: [],
      },
    ],
  };

  const { handleClose, clearSelectedRule, getApprovalLevelPayload } = props;

  const history = useHistory();
  const location = useLocation();
  const { ruleCode } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState({ ...defaultFormData });
  const [approvalData, setApprovalData] = useState(null);
  const {
    fetchApprovers: { data: approversData, loading: isFetchingApprovers },
    fetchTransactionTypes: { loading: isFetchingTransactionTypes },
    fetchApprovalRuleActivity: { selectedRuleCode, selectedRule },
    fetchApprovalRule: {
      data: approvalRuleData,
      loading: isSingleFetching,
      success: isSingleFetched,
    },
    deleteApprovalLevel: { success: approvalLevelDeleted },
  } = useSelector(({ approval }) => approval);

  const {
    fetchCategories: { loading: isCatLoading },
  } = useSelector(({ categories }) => categories);

  const {
    getVendor: { loading: vendorLoading },
  } = useSelector(({ vendors }) => vendors);

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_BLOCK_APPROVAL, blockType: 'fetchApprovalRule' });
      dispatch({ type: RESET_BLOCK_APPROVAL, blockType: 'createApprovalRule' });
      dispatch({ type: RESET_BLOCK_APPROVAL, blockType: 'fetchApprovalRuleActivity' });
      dispatch({ type: RESET_BLOCK_APPROVAL, blockType: 'editApprovalRule' });
      dispatch({ type: RESET_BLOCK_APPROVAL, blockType: 'addNewApproverLevel' });
      dispatch({ type: RESET_BLOCK_APPROVAL, blockType: 'deleteApprovalLevel' });
    };
  }, []);

  useEffect(() => {
    if (selectedRuleCode || ruleCode) {
      dispatch(fetchApprovalRule(selectedRuleCode || ruleCode));
    } else {
      setApprovalData(null);
    }
  }, [selectedRuleCode, ruleCode]);

  useEffect(() => {
    if (isSingleFetched && approvalRuleData) {
      setApprovalData(approvalRuleData);
    } else {
      setApprovalData(null);
    }
  }, [isSingleFetched, approvalRuleData]);

  useEffect(() => {
    if (approvalLevelDeleted) {
      setTimeout(() => {
        dispatch(fetchApprovalRule(selectedRuleCode || ruleCode));
      }, 200);
    }
  }, [approvalLevelDeleted]);

  const isLoading = isSingleFetching && isFetchingApprovers && isFetchingTransactionTypes;

  return (
    <div className="py-5 approval-page-body">
      <div className="approval-header">
        <div className="back-click mb-4 ms-4" onClick={() => history.goBack(-1)}>
          <ArrowLeftOutlined />
          Back
        </div>
      </div>
      <div className="card-modal-body">
        <ApprovalForm
          {...props}
          isEditing={selectedRule || ruleCode ? true : false}
          data={data}
          setData={setData}
          selectedRule={approvalData}
          getApprovalLevelPayload={getApprovalLevelPayload}
          fetchingApprovers={isFetchingApprovers}
        />
      </div>
    </div>
  );
}
