import React from 'react';
import './index.scss';
import EmptyTaskCard from 'components/UI/EmptyTaskCard';
import { useHistory } from 'react-router-dom';

import classNames from 'classnames';

import { ArrowRight } from 'assets/icons';

const OverviewCard = ({
  title,
  children,
  isEmpty,
  placeHolderText = null,
  minHeight = 350,
  customClass = '',
  containerClass = '',
  routeTo = '',
  marginTop,
}) => {
  const history = useHistory();
  return (
    <div
      className={classNames('overview-card-view', containerClass)}
      style={{ minHeight }}
    >
      {title && (
        <div className=" px-4 py-3 card-title justify-content-between w-100 border-bottom">
          {title}
        </div>
      )}
      <div
        className={classNames('card-list px-4 h-100 overflow-x-scroll pt-4', customClass)}
      >
        {isEmpty ? (
          <EmptyTaskCard text={placeHolderText} marginTop={marginTop} />
        ) : (
          children
        )}
      </div>

      {routeTo && (
        <div className="d-flex px-4 pb-4 justify-content-end">
          <button className="view-all" onClick={() => history.push(routeTo)}>
            {' '}
            View all
            <ArrowRight color="#D28B28" />
          </button>
        </div>
      )}
    </div>
  );
};

export default OverviewCard;
