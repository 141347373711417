import { Tooltip, Skeleton } from 'antd';
import { StatusIcon, TrashIcon } from 'assets/icons';
import PdfIcon from 'assets/images/pdfIcons.png';
import jpgIcon from 'assets/images/jpgIcon.png';
import pngIcon from 'assets/images/pngIcon.png';
import xlsxIcon from 'assets/images/xlsxIcons.png';
import csvIcon from 'assets/images/csvIcon.png';
import mp4Icon from 'assets/images/mp4Icon.png';
import mp3Icon from 'assets/images/mp3Icon.png';
import { ReactComponent as CloudArrowUp } from 'assets/icons/CloudArrowUp.svg';
import cs from 'classnames';
import { forwardRef, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFileData, uploadFiles } from 'redux/actions/AssetsAction';
import { awsUploadNew } from 'utils/FileUploadUtil';
import Loading from '../Loading';
import { toastError } from '../toast';
import './styles.scss';

export const ICONS = {
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': xlsxIcon,
  'text/csv': csvIcon,
  'application/pdf': PdfIcon,
  'image/jpg': jpgIcon,
  'video/mp4': mp4Icon,
  'audio/mp3': mp3Icon,
  'image/jpeg': jpgIcon,
  'image/png': pngIcon,
};

const FileUpload = forwardRef(
  (
    {
      label,
      wrapperClass,
      onChange,
      setUploadingFile,
      uploadingFile,
      supportType = 'Supported file types: jpeg, png, pdf. Max file size: 5mb',
      cloud = true,
      acceptedFile = {
        'image/jpeg': ['.jpeg', '.jpg', '.png'],
        'application/pdf': ['.pdf'],
      },
      externalUpload,
      checkIsDisabled,
      removeFile,
      setRemoveFile,
      uploadedFile,
      name = 'file',
      multiple = false,
      isRounded,
      uploadedFileComponent: UploadedFileComponent,
    },
    ref,
  ) => {
    const dispatch = useDispatch();
    const {
      uploadFiles: { data = [], success: assetSuccess, loading },
    } = useSelector(({ assets }) => assets);

    const { fileData: templateFile = {} } = useSelector(({ assets }) => assets);

    const [fileName, setFileName] = useState('');
    const [progress, setProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [uploadedDiv, setUploadedDiv] = useState(false);
    const [uploads, setUploads] = useState([]);
    const [uploadedItem, setUploadedItem] = useState();

    useEffect(() => {
      if (!!data?.length) {
        setUploads([...uploads, ...data]);
      }
    }, [data]);

    useMemo(() => {
      if (multiple) {
        if (onChange) {
          onChange(uploads);
        }
      }
    }, [uploads]);

    const handleSelect = async (val) => {
      setUploading(false);
      setFileName(val.fileName);
      setUploadingFile && setUploadingFile(false);
      if (onChange) {
        onChange(val);
        setUploadedItem(val);
      }
    };

    const onDrop = useCallback((acceptedFiles, rejectedFiles, e) => {
      if (rejectedFiles && rejectedFiles.length > 0) {
        toastError('File size exceeded');
        return;
      }
    }, []);

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
      disabled: uploadingFile,
      maxFiles: multiple ? 10 : 1,
      accept: acceptedFile,
      maxSize: 5 * 1024 * 1024,
      onDrop,
    });

    useEffect(() => {
      if (multiple) {
        setUploading(loading);
        setUploadedDiv(loading);
        setUploadingFile && setUploadingFile(loading);
      }
    }, [loading]);

    useEffect(() => {
      if (acceptedFiles.length > 0 && cloud && multiple) {
        dispatch(uploadFiles({ acceptedFiles }));
        return;
      }

      if (Object.keys(templateFile).length || acceptedFiles.length === 1) {
        const file = Object.keys(templateFile).length ? templateFile : acceptedFiles[0];

        const handleFileChange = async (file) => {
          setUploadedItem({ file });
          if (cloud) {
            setUploading(true);
            setUploadedDiv(true);
            setUploadingFile && setUploadingFile(true);

            const payload = {
              fileName: file.name,
              fileMime: file.type,
            };

            const upload = await awsUploadNew(
              payload,
              file,
              setProgress,
              setUploading,
              handleSelect,
            );

            if (upload === 'error') {
              setUploadingFile && setUploadingFile(false);
              handleRemove();
              setFileName('');
              throw new Error(error.message);
            }
            // else {
            //   getAssetCode()
            // }
          } else {
            setRemoveFile(false);
            setFileName(file.name);
            onChange({ file, fileSize: file.size, fileName: file.name });
            setUploadedItem({ file, fileSize: file.size, fileName: file.name });
            setUploadedDiv(true);
          }
        };

        handleFileChange(file);
      }
    }, [acceptedFiles, templateFile]);

    const handleRemove = () => {
      setFileName('');
      setProgress(0);
      onChange({
        fileName: '',
        fileType: '',
        key: '',
        originalFilename: '',
        signedUrl: '',
        url: '',
        assetCode: '',
        fileSize: '',
      });
      setUploadedItem({
        fileName: '',
        fileType: '',
        key: '',
        originalFilename: '',
        signedUrl: '',
        url: '',
        assetCode: '',
        fileSize: '',
      });

      setUploadedDiv(false);
      if (Object.keys(templateFile).length) dispatch(uploadFileData({}));
    };

    const handleMultipleRemove = (code) => {
      if (code) {
        let newArray = [...uploads];
        newArray = newArray.filter((file) => file.assetCode !== code);
        setUploads(newArray);
      } else {
        setUploads([]);
      }
    };

    useEffect(() => {
      if (uploadedFile || Object.keys(templateFile).length) {
        setUploadedDiv(true);
      } else {
        setUploadedDiv(false);
      }
    }, [uploadedFile]);

    useEffect(() => {
      if (removeFile) {
        if (multiple) handleMultipleRemove();
        else handleRemove();
      }
    }, [removeFile]);

    const uploadedName = uploadedFile ? 'Change file' : undefined;
    const UploadedFileComponents = ({
      isFileUploading,
      uploadedFileName,
      handleRemove,
      fileSize,
      fileType,
    }) => {
      return (
        <Fragment>
          {isFileUploading ? (
            <div className="py-3 px-3 rounded-4 d-flex justify-content-between uploaded-file-container">
              {isRounded ? (
                <div className={'add-more'}>
                  {(uploading || externalUpload) && <Loading color="#D28B28" size={20} />}
                </div>
              ) : (
                <div className="d-flex align-items-center gap-3">
                  <div>
                    <Skeleton.Avatar
                      size={32}
                      shape="square"
                      active
                      style={{ borderRadius: 6 }}
                    />
                  </div>

                  <div className="d-flex flex-column w-100 mt-1">
                    {Array.from({ length: 2 }).map((_, i) => (
                      <Skeleton.Input
                        key={i}
                        active
                        size={14}
                        style={{ borderRadius: 4, minWidth: 'unset', width: '200px' }}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="py-3 px-3 rounded-4 d-flex justify-content-between uploaded-file-container">
              <div className="d-flex  align-items-center">
                <img src={ICONS[fileType] || pngIcon} alt="icon" style={{ height: 28 }} />
                <div className="ms-3">
                  <p className="mb-1 p-0 m-0 uploaded-file-container-name">
                    {uploadedFileName}
                  </p>
                  {fileSize && (
                    <p className="uploaded-file-container-details gap-1 d-flex align-items-center p-0 m-0">
                      {Math.round(fileSize / 1024)} KB •{' '}
                      <span className="d-flex align-items-center gap-1">
                        <StatusIcon />
                        Completed
                      </span>{' '}
                    </p>
                  )}
                </div>
              </div>
              <div onClick={() => handleRemove()}>
                <TrashIcon />
              </div>
            </div>
          )}
        </Fragment>
      );
    };

    return (
      <div
        className={cs('fileUpload groupWrapper cursor', { [wrapperClass]: wrapperClass })}
      >
        <div>
          <label className="uploadFileLabel">{label}</label>
          {!uploadedDiv && (
            <div>
              {isRounded ? (
                <Tooltip title="Add more files">
                  <div
                    onClick={uploadingFile ? checkIsDisabled : null}
                    className="add-more position-relative"
                  >
                    <div
                      {...getRootProps({
                        className:
                          'dropzone w-100 h-100 d-flex justify-content-center align-items-center',
                      })}
                    >
                      <input
                        {...getInputProps({ ref })}
                        id={name}
                        name={name}
                        className="position-absolute w-100 h-100"
                        disabled={uploadingFile}
                        style={{
                          zIndex: 1,
                          right: 0,
                          left: 0,
                          inset: 0,
                          opacity: 0,
                        }}
                      />
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.9999 7.3335V16.6668M7.33325 12.0002H16.6666"
                          stroke="#A9A29D"
                          strokeWidth="1.33333"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </Tooltip>
              ) : (
                <div
                  className="upload-container w-100 position-relative"
                  onClick={uploadingFile ? checkIsDisabled : null}
                >
                  <div
                    {...getRootProps({
                      className: 'dropzone w-100 h-100 d-flex flex-column',
                    })}
                  >
                    <input
                      {...getInputProps({ ref })}
                      id={name}
                      className="position-absolute w-100 h-100 d-flex flex-column"
                      name={name}
                      disabled={uploadingFile}
                      style={{
                        zIndex: 1,
                        right: 0,
                        left: 0,
                        inset: 0,
                        opacity: 0,
                      }}
                    />

                    <div className="uploadFileIcon">
                      <CloudArrowUp />
                    </div>
                    <div className="uploadFileTextBorder">
                      <div className="text-sm fw-medium text-black">
                        Choose a file or drag & drop it here.
                      </div>
                      <div className="text-xs">{supportType}</div>

                      <button className="btn border xs">Browse file</button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        {uploadedDiv && (
          <>
            {UploadedFileComponent ? (
              <UploadedFileComponent
                isFileUploading={uploading}
                uploadedFileName={fileName}
                handleRemove={handleRemove}
                fileType={uploadedItem?.file?.type}
              />
            ) : (
              <UploadedFileComponents
                isFileUploading={uploading || externalUpload}
                uploadedFileName={fileName || uploadedName}
                handleRemove={handleRemove}
                fileSize={uploadedItem?.fileSize}
                fileType={uploadedItem?.file?.type}
              />
            )}
          </>
        )}

        {!!uploads.length &&
          uploads.map((item, index) => (
            <div key={index} className="my-2">
              <UploadedFileComponents
                isFileUploading={uploading || externalUpload}
                uploadedFileName={item.fileName}
                handleRemove={() => handleMultipleRemove(item.assetCode)}
                fileSize={item.fileSize}
                fileType={item?.fileType}
              />
            </div>
          ))}
      </div>
    );
  },
);
export default FileUpload;
