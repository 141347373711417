import LineStatus from 'components/LineStatus';
import { Badge, Col } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { getCurrency, percentageValue } from 'utils/helper';
import { numFormatter } from 'utils/utility';
import './styles.scss';

const BudgetsCard = ({ budget, customCardStyles }) => {
  const {
    status = '',
    code = '',
    name = '',
    currency = '',
    available = 0,
    disbursed = 0,
    amount = 0,
    spent = 0,
    schedule = {},
  } = budget;
  const history = useHistory();

  const { permissions } = allPermissions();
  const canManage = hasPermission({
    permissions,
    scopes: ['budget-edit', 'budget-*'],
  });
  const overview = () => {
    if (status === 'deleted' || (['inactive', 'pause'].includes(status) && !canManage))
      return;
    return history.push(`/expenses/budgets/${code}/overview`);
  };

  const dispatch = useDispatch();
  const {
    getStatistics: { data: dataStats = {} },
    getCompany: { data: companyData = {} },
  } = useSelector(({ companies }) => companies);

  // useEffect(() => {
  //   if (companyData.code) dispatch(getCompanyStats({ id: companyData.code }));
  // }, [companyData]);
  // const { available: { NGN: availableBalance = 0 } = {} } = dataStats;

  // const available = Math.min(availableBalance, bugetBalance);

  return (
    <Col md={6}>
      <div
        className="border-full p-4 mb-4 rounded tab-hover-effect cursor"
        onClick={() => overview()}
      >
        <div className="d-flex align-items-center justify-content-between pb-4 cursor">
          <div className="budgets-header">
            <h2 className={customCardStyles ? customCardStyles.title : ''}>
              {name}
              {['inactive', 'pause'].includes(status) && (
                <Badge className="text-uppercase balance-tag dark-bg text-white text-dark">
                  inactive
                </Badge>
              )}
              {status === 'closed' && (
                <Badge className="text-uppercase balance-tag dark-bg text-white text-dark">
                  Closed
                </Badge>
              )}
              {schedule?.occurrence &&
                !['inactive', 'pause', 'closed'].includes(status) && (
                  <div className="ms-2 recurring-tag ">{schedule?.occurrence}</div>
                )}
            </h2>
            {canManage && (
              <span className="fw-200">
                Budget goal:{' '}
                <b>
                  <CurrencyFormat
                    prefix={getCurrency(currency)}
                    value={numFormatter(amount / 100)}
                    displayType="text"
                    thousandSeparator={true}
                    isNumericString
                  />
                </b>
              </span>
            )}
          </div>
          <div>
            {/* <OverlayingIcons
              userList={[
                { user: { firstName: 'David' } },
                { user: { firstName: 'David' } },
                { user: { firstName: 'David' } },
                { user: { firstName: 'David' } },
              ]}
              value={[
                { user: { name: 'Test', url: '' } },
                { user: { name: 'Test', url: UserImg } },
                { user: { name: 'Test', url: '' } },
                { user: { name: 'Test', url: '' } },
              ]}
            /> */}
          </div>
        </div>
        <LineStatus
          active={percentageValue(amount, spent)}
          pending={percentageValue(amount, disbursed)}
          exceeded={available < 0}
        />
        <div className="d-flex flex-wrap pt-4">
          <div className="budgets-amount">
            <p>
              Spent <span className="tiny-square spent-dot" />
            </p>
            <h3 className={`fw-200 ${customCardStyles ? customCardStyles.currency : ''}`}>
              <CurrencyFormat
                prefix={getCurrency(currency)}
                value={numFormatter(spent / 100)}
                displayType="text"
                thousandSeparator={true}
                isNumericString
              />
            </h3>
          </div>
          <div className="budgets-amount">
            <p>
              {canManage ? 'Allocated' : 'Total budget'}
              <span className="tiny-square active-line disbursed-dot" />
            </p>
            <h3 className={`fw-200 ${customCardStyles ? customCardStyles.currency : ''}`}>
              {disbursed === 0 && !canManage ? (
                'Unlimited'
              ) : (
                <CurrencyFormat
                  prefix={getCurrency(currency)}
                  value={numFormatter(disbursed / 100)}
                  displayType="text"
                  thousandSeparator={true}
                  isNumericString
                />
              )}
            </h3>
          </div>
          <div className="budgets-amount">
            <p>
              Available <span className="tiny-square available-dot" />
            </p>
            <h3
              className={`text-dark ${customCardStyles ? customCardStyles.currency : ''}`}
            >
              <CurrencyFormat
                prefix={getCurrency(currency)}
                value={numFormatter(available < 0 ? 0 : available / 100)}
                displayType="text"
                thousandSeparator={true}
                isNumericString
              />
            </h3>
          </div>
          {available < 0 && (
            <div className="budgets-amount">
              <p>
                Overspent <span className="tiny-square overdraft" />
              </p>

              <h3
                className={`text-dark ${
                  customCardStyles ? customCardStyles.currency : ''
                }`}
              >
                <CurrencyFormat
                  prefix={getCurrency(currency)}
                  value={numFormatter((-1 * available) / 100)}
                  displayType="text"
                  thousandSeparator={true}
                  isNumericString
                />
              </h3>
            </div>
          )}
        </div>
      </div>
    </Col>
  );
};
export default BudgetsCard;
