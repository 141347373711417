import { useEffect, useState, memo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'react-bootstrap';
import MailTo from './components/Mailto';

import DeleteDialog from 'components/DeleteDialog';
import PendingApprovals from 'pages/Approvals/PendingApprovals';
import TopBar from 'components/TopBar';
import AllBills from './components/Tables/All';
import DraftBills from './components/Tables/Draft';
import ArchivedBills from './components/Tables/Archived';
import ScheduledBills from './components/Tables/Scheduled';
import EmptyData from './components/EmptyData';
import BillsEmptyState from './components/EmptyState';

import { fetchPendingApprovals } from 'redux/actions/ApprovalAction';
import { SELECTED_TABLE_ROWS } from 'redux/reducers/TableReducer';
import CustomButton from 'components/UI/CustomButton';
import { resetUploadState } from 'redux/actions/ProgressUploadAction';

import { allPermissions } from 'utils/AllowedTo';
import RequestFund from 'components/FundRequest/RequesterModal/RequestFunds';

import { RESET_FLAGS_BILL } from 'redux/reducers/BillReducer';

import {
  fetchBills,
  fetchDraftBills,
  fetchScheduledBills,
  deleteBill,
  deleteScheduledBill,
  fetchArchivedBills,
  updateBillPayload,
} from 'redux/actions/BillAction';

import './index.scss';
import { Tab, Tabs } from 'react-bootstrap';
import {
  InfoCircle02,
  XcloseIcon,
  BoxIcon,
  EditIcon04,
  File06,
  EyeIcon,
} from 'assets/icons';

const Bills = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(null);
  const [filteredQuery, setFilteredQuery] = useState({});

  const { isAdmin } = allPermissions();
  const locationArray = location.pathname.split('/');
  const [key, setKey] = useState(locationArray[locationArray.length - 1]);

  const {
    fetchBills: { loading, success, data },
    draftBills: { loading: draftLoading, success: draftSuccess, data: draft },
    fetchScheduledBills: {
      loading: scheduleLoading,
      success: scheduleSuccess,
      data: scheduledBill,
    },

    archivedBills: {
      loading: archiveLoading,
      success: archiveSuccess,
      data: archivedBill,
    },

    deleteBill: { loading: isDeleting, success: deleteSuccess },
    deleteScheduledBill: { loading: isDeletingSchedule, success: deleteScheduleSuccess },
  } = useSelector(({ bills }) => bills);

  const { fetchPendingApprovals: listPendingApprovals } = useSelector(
    ({ approval }) => approval,
  );

  useEffect(() => {
    if (!data?.bills?.length) {
      dispatch(fetchBills());
      dispatch(fetchDraftBills());
      dispatch(fetchScheduledBills());
      dispatch(fetchArchivedBills());
      dispatch(fetchPendingApprovals({ status: 'pending', entity: 'bill' }));
    }
  }, []);

  useEffect(() => {
    if (deleteSuccess || deleteScheduleSuccess) {
      setDeleteModal(null);
      dispatch(fetchBills());
      dispatch({ type: RESET_FLAGS_BILL, blockType: 'deleteBill' });
      dispatch({ type: RESET_FLAGS_BILL, blockType: 'deleteScheduledBill' });
    }
  }, [deleteSuccess, deleteScheduleSuccess]);

  const hasQuery = !!Object.keys(filteredQuery || {}).length;

  const [bannerVisible, setBannerVisible] = useState(
    // diasbled in the while
    // !localStorage.getItem('bannerDismissed'),
    false,
  );

  const handleSelect = (tabKey) => {
    setKey(tabKey);
    if (tabKey !== 'bills') {
      history.push(`/expenses/bills/${tabKey}`);
    } else {
      history.push(`/expenses/bills`, { disableFade: true });
    }
  };

  const addBill = () => {
    history.push('/expenses/bills/create');
    dispatch(resetUploadState());
    dispatch({ type: RESET_FLAGS_BILL, blockType: 'payload' });
    dispatch(updateBillPayload('products', [{ name: '', quantity: 1, unitPrice: '' }]));
  };

  const handleDelete = () => {
    const isSchedule = deleteModal?.split('_')[0] === 'sbl';

    const action = isSchedule
      ? deleteScheduledBill(deleteModal)
      : deleteBill(deleteModal);

    dispatch(action);
  };

  const handleRowSelect = (rowData, type) => {
    dispatch({
      type: SELECTED_TABLE_ROWS,
      payload: { selectedRows: rowData, type },
    });
  };
  const [isPopoverOpen, setIsPopoverOpen] = useState(true);

  const clearLocationState = () => {
    switch (key) {
      case 'scheduled':
        return dispatch(fetchScheduledBills());

      case 'drafts':
        return dispatch(fetchDraftBills());

      case 'archive':
        return dispatch(fetchArchivedBills());

      default:
        return dispatch(fetchBills());
    }
  };

  const ActionButtons = memo(() => {
    return (
      <div className="d-flex gap-3">
        <button className="add-custom d-none add-button" onClick={() => setIsOpen(true)}>
          <span className="ms-1" style={{ fontWeight: 500 }}>
            Request funds
          </span>
        </button>

        <CustomButton className="add-button gap-2" onClick={addBill}>
          <File06 />
          <span>Add bill</span>
        </CustomButton>
      </div>
    );
  });

  const BillActions = ({ list }) => {
    const handleEdit = (code) => {
      if (code)
        history.push({
          pathname: `/expenses/bills/review/${code}`,
          search: '?isEdit=true',
        });
    };

    const handleAction = (event, action, data) => {
      event?.stopPropagation();
      event?.preventDefault();
      setIsPopoverOpen(true);

      if (action === 'delete') {
        setDeleteModal(data?.code);
      }
    };

    return (
      <div className="actions-dialog">
        <div className="actionLink gap-8" onClick={() => setIsPopoverOpen(true)}>
          <EyeIcon
            style={{
              marginRight: '0px',
              color: '#79716B',
            }}
          />{' '}
          View details
        </div>

        {['draft'].includes(list.status?.value) && (
          <div className="actionLink gap-8" onClick={() => handleEdit(list?.code)}>
            <EditIcon04
              style={{
                marginRight: '0px',
                color: '#57534E',
              }}
            />{' '}
            Edit details
          </div>
        )}

        <div
          className="actionLink gap-8"
          onClick={(e) => handleAction(e, 'delete', list)}
        >
          <BoxIcon
            style={{
              marginLeft: '2px',
              marginRight: '0px',
            }}
          />{' '}
          Archive
        </div>
      </div>
    );
  };

  const renderBills = () => {
    switch (key) {
      case 'drafts':
        return (
          <>
            <DraftBills
              data={draft}
              isAdmin={isAdmin}
              loading={draftLoading}
              BillActions={BillActions}
              isPopoverOpen={isPopoverOpen}
              setIsPopoverOpen={setIsPopoverOpen}
              handleDelete={(code) => setDeleteModal(code)}
            />
          </>
        );

      case 'scheduled':
        return (
          <>
            <ScheduledBills
              isAdmin={isAdmin}
              data={scheduledBill}
              loading={scheduleLoading}
              clearLocationState={clearLocationState}
              handleDelete={(code) => setDeleteModal(code)}
            />
          </>
        );

      case 'archive':
        return <ArchivedBills data={archivedBill} loading={archiveLoading} />;

      case 'requests':
        return (
          <PendingApprovals
            typeQuery="bill"
            onRowSelect={(data) => {
              handleRowSelect(data, 'requests');
            }}
          />
        );

      default:
        return (
          <AllBills
            data={data}
            isAdmin={isAdmin}
            filteredQuery={filteredQuery}
            BillActions={BillActions}
            isPopoverOpen={isPopoverOpen}
            hasQuery={hasQuery}
            setIsPopoverOpen={setIsPopoverOpen}
            clearLocationState={clearLocationState}
            setFilteredQuery={(data) => setFilteredQuery(data)}
          />
        );
    }
  };

  return (
    <>
      <div className="budgets-wrapper bills position-relative container">
        <TopBar
          headerText="Bills"
          subText="Organize and access your bills"
          isRightBar
          addButton
          addActionButton={<ActionButtons />}
        />

        <div className="mt-3">
          <Banner bannerVisible={bannerVisible} setBannerVisible={setBannerVisible} />

          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={handleSelect}
            className="mb-2 mt-4"
          >
            <Tab
              eventKey="bills"
              title={
                <div className="d-flex">
                  All{' '}
                  <div className="count">
                    <span className="m-auto">{data?.meta?.total || 0}</span>
                  </div>
                </div>
              }
              tabClassName="new-tab"
            ></Tab>

            <Tab
              eventKey="requests"
              title={
                <div className="d-flex">
                  Need review{' '}
                  <div className="count">
                    <span className="m-auto">
                      {listPendingApprovals?.meta?.total || 0}
                    </span>
                  </div>
                </div>
              }
              tabClassName="new-tab"
            ></Tab>

            <Tab
              eventKey="scheduled"
              title={
                <div className="d-flex">
                  Scheduled{' '}
                  <div className="count">
                    <span className="m-auto">{scheduledBill?.meta?.total || 0}</span>
                  </div>
                </div>
              }
              tabClassName="new-tab"
            ></Tab>

            <Tab
              eventKey="drafts"
              title={
                <div className="d-flex">
                  Drafts{' '}
                  <div className="count">
                    <span className="m-auto">{draft?.meta?.total || 0}</span>
                  </div>
                </div>
              }
              tabClassName="new-tab"
            ></Tab>

            <Tab
              eventKey="archive"
              title={
                <div className="d-flex">
                  Archived{' '}
                  <div className="count">
                    <span className="m-auto">{archivedBill?.meta?.total || 0}</span>
                  </div>
                </div>
              }
              tabClassName="new-tab"
            ></Tab>
          </Tabs>

          <section
            className="overview-holder p-0"
            style={{ height: `calc(100vh - ${!bannerVisible ? '11.5' : '15.5'}rem)` }}
          >
            <div className="fade-in">{renderBills()}</div>
          </section>
        </div>
      </div>

      <Modal show={!!deleteModal} centered dialogClassName="custom-dialog">
        <DeleteDialog
          icon={<BoxIcon height="24" width="24" />}
          title="Archive bill"
          subTitle="Are you sure you want archive this bill?"
          onCancel={() => setDeleteModal(null)}
          onDelete={handleDelete}
          deleteText="Archive"
          isLoading={isDeleting || isDeletingSchedule}
        />
      </Modal>

      <RequestFund isOpen={isOpen} toggleHandler={() => setIsOpen(false)} />
    </>
  );
};

const Banner = ({ bannerVisible, setBannerVisible }) => {
  const handleClose = () => {
    setBannerVisible(false);
    localStorage.setItem('bannerDismissed', 'true');
  };

  return (
    <>
      {bannerVisible && (
        <div className="banner">
          <div className="d-flex gap-2">
            <span>
              <InfoCircle02 className="mt-1" />
            </span>

            <span className="text">
              You can also send an email and have it reflect in your drafts. Send to
              <span className="ps-1">
                <MailTo />
              </span>
            </span>
          </div>

          <span className="close-icon" onClick={handleClose}>
            <XcloseIcon width="16" height="16" stroke="#79716B" />
          </span>
        </div>
      )}
    </>
  );
};

export default Bills;
