import { CopyIcon, PencilIcon } from 'assets/icons';
import DeleteDialog from 'components/DeleteDialog';
import Timeline from 'components/Timeline';
import DetailsTimeline from 'components/Timeline/DetailsTimeline';
import ItemInfo from 'components/TransactionModal/itemInfo';
import CustomButton from 'components/UI/CustomButton';
import DrawerBody from 'components/UI/CustomDrawer/components/DrawerBody';
import ReceiptList from 'components/UI/CustomDrawer/components/ReceiptList';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import CategoryDropdown from 'components/UI/CustomSelect/CategoryDropdown';
import { CustomSelectRadio } from 'components/UI/CustomSelectRadio';
import CustomTextarea from 'components/UI/CustomTextarea';
import FileUpload from 'components/UI/FileUpload';
import Loading from 'components/UI/Loading';
import { toastError } from 'components/UI/toast';
import ApprovalModal from 'pages/Approvals/components/ApprovalModal';
import { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getBalances } from 'redux/actions/BudgetsAction';
import { deleteCategories } from 'redux/actions/CategoryAction';
import {
  deleteRequests,
  getRequestAssets,
  updateRequests,
} from 'redux/actions/RequestsAction';
import { getVendors } from 'redux/actions/VendorsAction';
import {
  getAvailableBalance,
  getFormattedDate,
  groupSourceOptions,
  transactionAcceptedFiles,
  transactionFileSupported,
} from 'utils/helper';

import ImgCard from 'components/UI/ImgCard';

const ViewRequestDetails = ({
  setStep,
  setIsLoading,
  selectRequest,
  setAssetViewer,
  getReceiptList,
  hideHeader,
}) => {
  const dispatch = useDispatch();

  const [deleting, setDeleting] = useState(false);
  const [showCategoryDeleteModal, setShowCategoryDeleteModal] = useState(false);

  const [receiptCode, setReceiptCode] = useState('');

  const {
    deleteRequest: { loading: isDeleting, success: successDelete },
    getSingleRequest: { data: singleData = {} },

    requestReceipts: { data: receiptAssets, loading: loadingReceipt },
  } = useSelector(({ requests }) => requests);

  const {
    getBalances: { data: balances, loading: loadingBalances },
  } = useSelector(({ budgets }) => budgets);

  const {
    fetchCategories: { data: categoryData },
    deleteCategories: { loading: loadingDelete },
  } = useSelector(({ categories }) => categories);

  const {
    getVendor: { loading: vendorLoadingData, data: vendorData, success: vendorSuccess },
  } = useSelector(({ vendors }) => vendors);

  const {
    requestData: { code, receipt, created_at: createdAt, user: requestUser } = {},
  } = selectRequest || {};

  const {
    status,
    description: requestDescription,
    approvalRequest = {},
  } = singleData?.data?.fundRequest ?? {};
  const { approvals: completedApprovals = [] } = approvalRequest || {};

  const {
    user: {
      data: { user },
    },
  } = useSelector(({ auth }) => auth);

  const [categoryVisible, setCategoryVisible] = useState(false);
  const [isEditingCategory, setIsEditingCategory] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});

  const [expenseCategories, setExpenseCategories] = useState({
    value: '',
  });

  const [budgetVisible, setBudgetVisible] = useState({
    isVisible: false,
    isLoading: false,
  });

  const [AccountVisible, setAccountVisible] = useState({
    isVisible: false,
    isLoading: false,
  });

  const [vendorVisible, setVendorVisible] = useState({
    isVisible: false,
    isLoading: false,
  });

  const [cardVisible, setCardVisible] = useState({
    isVisible: false,
    isLoading: false,
  });

  const [ruleCode, setRuleCode] = useState(null);
  const [newBudget, setNewBudget] = useState({
    visible: false,
    data: '',
    isEditingBudget: false,
  });
  const [description, setDescription] = useState({
    visible: false,
    data: '',
    isEditingDescription: false,
  });
  const [saveButton, setSaveButton] = useState(false);

  const createByMe = user?.code === requestUser?.code;

  useEffect(() => {
    if (!vendorData?.vendors?.length) dispatch(getVendors());
  }, []);

  const generateVendor = useMemo(() => {
    return vendorData?.vendors?.map(({ code: value, name: label }) => ({
      label,
      value,
    }));
  }, [vendorSuccess]);

  const canFetchAsset = !!singleData?.data?.fundRequest?.receipts?.length;

  useEffect(() => {
    if (!!canFetchAsset) {
      dispatch(getRequestAssets(singleData?.data?.fundRequest?.receipts));
    }
  }, [canFetchAsset]);

  const onHandleGetSelected = (value) => {
    editRequest(code, value.value);
    setCategoryVisible(false);

    setExpenseCategories({ value });
  };

  const editRequest = (code, categoryCode) => {
    dispatch(updateRequests({ code, category: categoryCode }));
    setIsEditingCategory(true);
  };

  useEffect(() => {
    if (requestDescription && !description?.data.length) {
      setDescription({
        ...description,
        data: requestDescription,
      });
    }
  }, [requestDescription]);

  const cancelDescriptionEdit = () => {
    setDescription({
      ...description,
      visible: false,
    });
    setReceiptCode('');
    setSaveButton(false);
  };

  const attemptDelete = () => {
    setDeleting(true);
  };

  const deleteRequest = () => {
    dispatch(deleteRequests(code));
  };

  const onOpenDeleteModal = (name, code) => {
    setSelectedCategory({
      code,
      name,
    });
    setShowCategoryDeleteModal(true);
  };

  useEffect(() => {
    if (successDelete) {
      setDeleting(false);
    }
  }, [successDelete]);

  useEffect(() => {
    if (receipt?.code) dispatch(getAssets(receipt?.code));
  }, [selectRequest]);

  const viewImgUrl = (asset) => {
    setAssetViewer(asset);
    getReceiptList(receiptAssets);
  };

  useEffect(() => {
    if (receiptCode?.receipt?.length) {
      setSaveButton(true);
    }
  }, [receiptCode?.receipt]);

  const onHandleDeleteCategory = () => {
    dispatch(deleteCategories(selectedCategory?.code));
  };

  const closeApprovalRule = () => setRuleCode(null);

  if (ruleCode)
    return (
      <ApprovalModal
        selectedRuleCode={ruleCode}
        isOpen={!!ruleCode}
        isEdit
        handleClose={closeApprovalRule}
        clearSelectedRule={closeApprovalRule}
      />
    );

  const openRule = (code) => {
    setRuleCode(code);
  };

  const requestMoreDetail = () => {
    hideHeader(true);
    setStep(2);
  };

  const handleDescriptionChange = (val) => {
    setDescription({
      ...description,
      data: val.target.value,
    });
    setSaveButton(true);
  };

  useEffect(() => {
    if (singleData?.data?.fundRequest?.meta?.budget) {
      setNewBudget({
        ...newBudget,
        data: singleData?.data?.fundRequest?.meta?.budget,
      });
    }
  }, [singleData?.data?.fundRequest?.meta?.budget]);

  const handleBudgetChange = (val) => {
    setNewBudget({
      ...newBudget,
      data: val.target.value,
    });
    setSaveButton(true);
  };

  const handleDescription = () => {
    if (description.visible) {
      if (description?.data !== singleData?.data?.fundRequest?.description) {
        setDescription({
          ...description,
          isEditingDescription: true,
        });
        return dispatch(updateRequests({ code, description: description?.data }));
      }
      return toastError('Make a change to description');
    } else if (newBudget.visible) {
      if (newBudget?.data !== singleData?.data?.fundRequest?.meta?.budget) {
        setNewBudget({
          ...newBudget,
          isEditingBudget: true,
        });
        return dispatch(
          updateRequests({
            code,
            meta: {
              budget: newBudget.data,
            },
          }),
        );
      }
      return toastError('Make a change to the budget name');
    } else if (!!receiptCode?.receipt?.length) {
      dispatch(updateRequests({ code, receipt: receiptCode?.receipt }));
      setReceiptCode({
        ...receiptCode,
        isUpdating: true,
      });
    }
  };

  const updateRequest = (value, type) => {
    if (type === 'card') {
      setCardVisible({ isLoading: true });
      return dispatch(updateRequests({ code, card: value }));
    }
    if (type === 'budget') {
      setBudgetVisible({ isLoading: true });
      return dispatch(updateRequests({ code, budget: value }));
    }
    if (type === 'vendor') {
      setVendorVisible({ isLoading: true });
      return dispatch(updateRequests({ code, vendor: value }));
    }
    if (type === 'account') {
      setAccountVisible({ isLoading: true });
      return dispatch(updateRequests({ code, bankAccount: value }));
    }
  };

  //
  useEffect(() => {
    dispatch(getBalances());
  }, []);

  const [loadPage, setLoadPage] = useState(0);

  useEffect(() => {
    if (loadPage > 1) dispatch(getBalances({ page: loadPage }));
  }, [loadPage]);

  const [hasMore, setHasMore] = useState(false);
  const [budgetList, setBudgetList] = useState([]);
  const [accountList, setAccountList] = useState([]);

  useEffect(() => {
    if (balances?.budgets?.length || balances?.balances?.length) {
      const budget = getAvailableBalance(balances?.budgets, 'budgets');
      const available_balance = getAvailableBalance(balances?.balances, 'balances');
      setBudgetList((prevOptions) => [...budget]);
      setAccountList((prevOptions) => [...available_balance]);
    }
  }, [balances?.budgets]);

  async function loadOptions(search, loadedOptions, { page }) {
    setLoadPage(page);

    let filteredOption = budgetList.filter((item) =>
      item?.name?.toLowerCase().includes(search?.toLowerCase()),
    );

    return {
      options: groupSourceOptions(filteredOption),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  async function loadAccountOptions(search, loadedOptions, { page }) {
    setLoadPage(page);

    let filteredOption = accountList.filter((item) =>
      item?.name?.toLowerCase().includes(search?.toLowerCase()),
    );

    return {
      options: groupSourceOptions(filteredOption),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  //

  const approvers = [];

  approvalRequest?.approvalStages?.forEach((item) => {
    item.approvers.forEach((approver) => {
      const user = approver.user;
      const approver_code = approver.code;
      approvers.push({ user, approver_code, status: 'pending', message: '' });
    });
  });

  completedApprovals?.forEach((item) => {
    approvers.push({
      user: item?.approver?.user,
      date: getFormattedDate(item?.updated_at),
      status: item?.status,
      message: item?.reason ?? '',
    });
  });

  const requesterName = `${singleData?.data?.fundRequest?.user?.firstName} ${singleData?.data?.fundRequest?.user?.lastName}`;
  const bodyData = [
    {
      title: 'Requested by',
      value: requesterName,
      icon: (
        <div>
          <ImgCard
            initials={singleData?.data?.fundRequest?.user?.firstName.slice('')[0]}
          />
        </div>
      ),
    },
    {
      title: 'Date',
      value: getFormattedDate(createdAt),
    },
    {
      title: 'Request ID',
      value: singleData?.data?.fundRequest?.code,
      isCopy: true,
      icon: <CopyIcon stroke="#D28B28" width="20" height="20" />,
    },
  ];

  return (
    <>
      <>
        <div className="card-modal-body">
          <div className="information-wrapper">
            <DrawerBody data={bodyData} />
            <div className="pt-3">
              <ItemInfo
                title="Description"
                value={singleData?.data?.fundRequest?.description}
                withAction={['pending'].includes(status)}
                hideValue={true}
                action={() => {
                  setDescription({ ...description, visible: !description.visible });
                  setSaveButton(!saveButton);
                }}
                isLoading={description.isEditingDescription}
                icon={
                  ['pending'].includes(status) &&
                  createByMe && <PencilIcon stroke="#D28B28" width="18" height="18" />
                }
              />
              <div className="padding-top-xxs">
                <CustomTextarea
                  name="description"
                  placeholder="Description"
                  value={description?.data}
                  disabled={
                    !description?.visible ||
                    (!['pending'].includes(status) && !createByMe)
                  }
                  onChange={handleDescriptionChange}
                  rowSize={4}
                />
              </div>
            </div>
            <div className="divider"></div>
            <Timeline data={approvers} goToRule={openRule} />
            {['pending'].includes(singleData?.data?.fundRequest?.status) &&
              createByMe &&
              !!singleData?.data?.fundRequest?.moreInfoLogs?.length && (
                <>
                  <div className="divider"></div>
                  <DetailsTimeline
                    data={singleData?.data?.fundRequest?.moreInfoLogs}
                    action={requestMoreDetail}
                  />
                </>
              )}
            <div className="divider"></div>
            {singleData?.data?.fundRequest?.category && (
              <div>
                <ItemInfo
                  title="Category"
                  value={
                    expenseCategories.value.label ??
                    singleData?.data?.fundRequest?.category?.name ??
                    'None'
                  }
                  withAction={status !== 'declined' && createByMe}
                  action={() => setCategoryVisible(!categoryVisible)}
                  isLoading={isEditingCategory}
                  icon={
                    status !== 'declined' &&
                    createByMe && <PencilIcon stroke="#D28B28" width="18" height="18" />
                  }
                />
              </div>
            )}

            {categoryVisible && (
              <div className="padding-top-xxs">
                <CategoryDropdown
                  placeholder="Select a category"
                  onChange={(val) => onHandleGetSelected(val)}
                  value={expenseCategories.value}
                  defaultValue={{
                    value: singleData?.data?.fundRequest?.category?.code,
                    label: singleData?.data?.fundRequest?.category?.name,
                  }}
                  name="category"
                />
              </div>
            )}
            {singleData?.data?.fundRequest?.vendor && (
              <div className="pt-3">
                <ItemInfo
                  title="Vendor"
                  isLink={
                    singleData?.data?.fundRequest?.budget?.code
                      ? `/expenses/vendors/${singleData?.data?.fundRequest?.vendor.code}`
                      : false
                  }
                  withAction={['pending'].includes(status)}
                  action={() => setVendorVisible({ isVisible: !vendorVisible.isVisible })}
                  value={singleData?.data?.fundRequest?.vendor?.name ?? 'N/A'}
                  isLoading={vendorVisible.isLoading}
                  icon={
                    createByMe && <PencilIcon stroke="#D28B28" width="18" height="18" />
                  }
                />
              </div>
            )}
            {vendorVisible.isVisible && (
              <div className="padding-top-xxs">
                <CustomSelect
                  placeholder="Select a vendor"
                  name="vendor"
                  isClearable
                  options={generateVendor}
                  onChange={(val) => updateRequest(val.value, 'vendor')}
                  defaultValue={{
                    value: singleData?.data?.fundRequest?.vendor?.code,
                    label: singleData?.data?.fundRequest?.vendor?.name,
                  }}
                />
              </div>
            )}

            {singleData?.data?.fundRequest?.card && (
              <div className="pt-3">
                <ItemInfo
                  title="Card"
                  isLink={
                    singleData?.data?.fundRequest?.card?.code
                      ? `/cards/${singleData?.data?.fundRequest?.card.code}`
                      : false
                  }
                  withAction={['pending'].includes(status)}
                  action={() => setCardVisible({ isVisible: !cardVisible.isVisible })}
                  value={singleData?.data?.fundRequest?.card?.name ?? 'N/A'}
                  isLoading={cardVisible.isLoading}
                  icon={
                    createByMe && <PencilIcon stroke="#D28B28" width="18" height="18" />
                  }
                />
              </div>
            )}

            {cardVisible.isVisible && (
              <div className="padding-top-xxs">
                <CustomSelect
                  label="Card"
                  name="card"
                  placeholder={`Select a card`}
                  defaultValue={
                    singleData?.data?.fundRequest?.card?.code && {
                      value: singleData?.data?.fundRequest?.card?.code,
                      label: singleData?.data?.fundRequest?.card?.name,
                    }
                  }
                  onChange={(val) => updateRequest(val.value, 'card')}
                  options={generateVendor}
                />
              </div>
            )}

            {singleData?.data?.fundRequest?.bankAccount?.balance && (
              <div className="pt-3">
                <ItemInfo
                  title="Account"
                  isLink={
                    singleData?.data?.fundRequest?.bankAccount?.balance?.code
                      ? `/accounts/${singleData?.data?.fundRequest?.bankAccount?.balance?.code}`
                      : false
                  }
                  withAction={['pending'].includes(status)}
                  action={() =>
                    setAccountVisible({ isVisible: !AccountVisible.isVisible })
                  }
                  value={
                    singleData?.data?.fundRequest?.bankAccount?.balance?.name ?? 'N/A'
                  }
                  isLoading={AccountVisible.isLoading}
                  icon={
                    ['pending'].includes(status) && (
                      <PencilIcon stroke="#D28B28" width="18" height="18" />
                    )
                  }
                />
              </div>
            )}

            {AccountVisible.isVisible && (
              <div className="padding-top-xxs">
                <CustomSelectRadio
                  placeholder="Select an account"
                  name="account"
                  onChange={(val) => updateRequest(val.value, 'account')}
                  defaultValue={
                    singleData?.data?.fundRequest?.bankAccount?.balance?.code && {
                      value: singleData?.data?.fundRequest?.bankAccount?.balance?.code,
                      label: singleData?.data?.fundRequest?.bankAccount?.balance?.name,
                    }
                  }
                  isLoading={loadingBalances}
                  isDisabled={loadingBalances}
                  loadOptions={loadAccountOptions}
                />
              </div>
            )}

            {singleData?.data?.fundRequest?.meta?.budget && (
              <div className="pt-3">
                <ItemInfo
                  title="New Budget Name"
                  withAction={['pending'].includes(status)}
                  value={singleData?.data?.fundRequest?.meta?.budget}
                  isLoading={newBudget.isEditingBudget}
                  action={() =>
                    setNewBudget({
                      ...newBudget,
                      visible: !newBudget.visible,
                    })
                  }
                  icon={
                    createByMe && <PencilIcon stroke="#D28B28" width="18" height="18" />
                  }
                />
              </div>
            )}

            {newBudget.visible && (
              <div className="padding-top-xxs">
                <CustomInput
                  label="Budget name"
                  placeholder="Enter name"
                  type="text"
                  name="budgetName"
                  onChange={handleBudgetChange}
                  value={newBudget.data}
                  maxLength="100"
                />
              </div>
            )}

            {singleData?.data?.fundRequest?.budget && (
              <div className="pt-3">
                <ItemInfo
                  title="Budget"
                  isLink={
                    singleData?.data?.fundRequest?.budget?.code
                      ? `/expenses/budgets/${singleData?.data?.fundRequest?.budget.code}/overview`
                      : false
                  }
                  withAction={['pending'].includes(status)}
                  action={() => setBudgetVisible({ isVisible: !budgetVisible.isVisible })}
                  value={singleData?.data?.fundRequest?.budget?.name ?? 'N/A'}
                  isLoading={budgetVisible.isLoading}
                  icon={
                    createByMe && <PencilIcon stroke="#D28B28" width="18" height="18" />
                  }
                />
              </div>
            )}
            {budgetVisible.isVisible && (
              <div className="padding-top-xxs">
                <CustomSelectRadio
                  placeholder="Select a budget"
                  name="budget"
                  onChange={(val) => updateRequest(val.value, 'budget')}
                  defaultValue={
                    singleData?.data?.fundRequest?.budget?.code && {
                      value: singleData?.data?.fundRequest?.budget?.code,
                      label: singleData?.data?.fundRequest?.budget?.name,
                    }
                  }
                  isLoading={loadingBalances}
                  isDisabled={loadingBalances}
                  loadOptions={loadOptions}
                />
              </div>
            )}

            <div className="pt-3">
              {loadingReceipt && <Loading color="#D28B28" size={20} />}
              {!loadingReceipt && receiptAssets?.length <= 0 && (
                <div>
                  <FileUpload
                    label="Receipts or Attachments"
                    placeholder="Upload receipts or invoices (PDF, JPEG, PNG, XLSX)"
                    name="file"
                    onChange={(value) =>
                      setReceiptCode({
                        receipt: value?.map((item) => item.assetCode),
                      })
                    }
                    removeFile={!receiptCode.receipt}
                    multiple
                    wrapperClass="new-receipt"
                    supportType={transactionFileSupported}
                    acceptedFile={transactionAcceptedFiles}
                  />
                </div>
              )}
              {!loadingReceipt && receiptAssets?.length > 0 && (
                <ReceiptList
                  receiptAssets={receiptAssets}
                  onChange={(value) =>
                    setReceiptCode({
                      receipt: value?.map((item) => item.assetCode),
                    })
                  }
                  removeFile={!receiptCode.receipt}
                  viewImgUrl={viewImgUrl}
                />
              )}
            </div>
          </div>
        </div>

        {saveButton && (
          <div className="card-modal-footer justify-content-end">
            <CustomButton
              onClick={cancelDescriptionEdit}
              className="base-button text-sm font-medium  black-transparent"
            >
              Cancel
            </CustomButton>
            <CustomButton
              onClick={handleDescription}
              className="base-button text-sm font-medium dark-button"
            >
              {description?.isEditingDescription ||
              newBudget.isEditingBudget ||
              receiptCode?.isUpdating ? (
                <Loading color="#D28B28" size={20} />
              ) : (
                'Save changes'
              )}
            </CustomButton>
          </div>
        )}

        {status === 'pending' && createByMe && !saveButton && (
          <div className="card-modal-footer justify-content-end">
            <CustomButton
              withoutBg
              fullWidth
              className="base-button danger-button text-sm font-medium"
              onClick={attemptDelete}
            >
              Delete request
            </CustomButton>
          </div>
        )}
      </>
      {showCategoryDeleteModal && (
        <DeleteDialog
          title={`Delete ${selectedCategory?.name} Category`}
          subTitle="Are you sure you want to delete this category? This action cannot be undone and all transactions in this category would be categorized as unknown"
          onCancel={() => setShowCategoryDeleteModal(false)}
          onDelete={onHandleDeleteCategory}
          styles={{ width: 400 }}
          isLoading={loadingDelete}
        />
      )}

      {deleting && (
        <Modal show={deleting} centered dialogClassName="custom-dialog">
          <DeleteDialog
            title={`Delete request`}
            subTitle="Are you sure you want to delete this request? This action can not be undone."
            onCancel={() => setDeleting(false)}
            onDelete={deleteRequest}
            styles={{ width: 400 }}
            isLoading={isDeleting}
          />
        </Modal>
      )}
    </>
  );
};
export default ViewRequestDetails;
